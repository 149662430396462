import React, { Component } from "react";
import "./Home.css";

class Home extends Component {
  render() {
    return (
      <div id="home">
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <h2>NATURES WAY TO NURTURE</h2>

              <h3 className="mt-5">
                Grounded healing has been on the market for 20 years. We believe
                in using the purest plant and method to extract the best of the
                oils in a way that legitimizes our product for nature to nurture
                the body, the mind and the spirit.
              </h3>
            </div>
          </div>
          <div className="learn_more col-12">
            <a href="#learn-more" onClick={this.smoothScroll}>
              <button className="btn-lg gh_btn_default">LEARN MORE</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
