import React, { Component } from "react";
import "./Products.css";

class Products extends Component {
  render() {
    return (
      <div id="products">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="font-semi-bold">Our Products</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <p>
                <span className="gh_green_2">FREE DELIVERY WITHIN 5KM</span> ,
                ABOVE 5KM <span className="gh_green_2">R100 NATIONALLY</span>{" "}
                AND INTERNATIONALLY CALCULATED ACCORDINGLY
              </p>
            </div>
          </div>
          <div className="row product-item">
            <div className="col-12 product-heading">
              <h3>
                Cannabis topical <span className="product-price">R500</span> (50ml)
              </h3>
            </div>
            <div className="col-md-5 col-sm-12 product-image center">
              <img
                src={require("../../assets/images/products/IMG_20220122_192459.jpg")}
                title="Cannabis topical"
                alt="Cannabis topical"
              />
            </div>
            <div className="col-md-7 col-sm-12 product-text">
              <p>
                Benefits:
                <ul>
                  <li>Provides localized pain management</li>
                  <li>
                    Treats skin irritation ( bug bites, scratches and other skin
                    abrasions)
                  </li>
                  <li>Alleviates symptoms of psoriasis</li>
                  <li>Reduces inflammation and arthritis</li>
                  <li>Enhances sexual pleasure</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row product-item">
            <div className="col-12 product-heading">
              <h3>
                THC Tetrahydrocannabinol drops{" "}
                <span className="product-price">R450</span> (10ml)
              </h3>
            </div>
            <div className="col-md-5 col-sm-12 product-image center">
              <img
                src={require("../../assets/images/products/IMG_20220124_152340.jpg")}
                className="product-image"
                title="THC Tetrahydrocannabinol drops"
                alt="THC Tetrahydrocannabinol drops"
              />
            </div>
            <div className="col-md-7 col-sm-12 product-text">
              <p>
                Benefits:
                <ul>
                  <li>Pain</li>
                  <li>Nausea and vomiting</li>
                  <li>Glaucoma</li>
                  <li>Side effects of chemotherapy and multiple Sclerosis</li>
                  <li>Symptoms associated with HIV/AIDS</li>
                  <li>Inflammation</li>
                  <li>Low appetite</li>
                  <li>Muscle spasticity</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row product-item">
            <div className="col-12 product-heading">
              <h3>
                CBD Oil drops <span className="product-price">R450</span>  (10ml)
              </h3>
            </div>
            <div className="col-md-5 col-sm-12 product-image center">
              <img
                src={require("../../assets/images/products/IMG_20220124_152340.jpg")}
                title="CBD Oil drops"
                alt="CBD Oil drops"
              />
            </div>
            <div className="col-md-7 col-sm-12 product-text">
              <p>
                Benefits:
                <ul>
                  <li>Pain</li>
                  <li>Insomnia</li>
                  <li>Anxiety</li>
                  <li>Symptoms related to epilepsy and seizures</li>
                  <li>IBS (Irritable Bowel Syndrome)</li>
                  <li>Depression</li>
                  <li>ADHD</li>
                  <li>Symptoms of Parkinson's disease</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row product-item">
            <div className="col-12 product-heading">
              <h3>
                Cannahoney <span className="product-price">R550</span> (50ml)
              </h3>
            </div>
            <div className="col-md-5 col-sm-12 center product-image">
              <img
                src={require("../../assets/images/products/IMG_20220131_113547.jpg")}
                title="Cannahoney"
                alt="Cannahoney"
              />
            </div>
            <div className="col-md-7 col-sm-12 product-text">
              <p>
                Benefits:
                <ul>
                  <li>Relieve from Cancer symptoms</li>
                  <li>Acne</li>
                  <li>Acid reflux</li>
                  <li>Burn/ cuts</li>
                  <li>Sinus ailment</li>
                  <li>Insomnia</li>
                  <li>Cough suppressant</li>
                  <li>Antibacterial and healing properties</li>
                  <li>Immune system booster</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row place-order">
            <div className="col-12">
              <a href="#contact" onClick={this.smoothScroll}>
                <button className="btn-lg order-btn gh_btn_secondary">
                  Place an order
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Products;
