import React, { Component } from "react";
import "./Navbar.css";

class Navbar2 extends Component {
  render() {
    return (
      <nav className="navbar fixed-top navbar-expand-sm navbar-light navbar-custom">
        <div className="container">
          <a className="navbar-brand" href="/#home">
            <img
              src={require("../../assets/images/Grounded_Healing_Logo.webp")}
              title="Grounded Healing"
              alt="Grounded Healing"
              width={100}
            />
          </a>
          <span className="navbar-heading">Grounded Healing</span>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a
                  className="nav-link"
                  href="/#home"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#products"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#testimonials"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Testimonials
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#contact"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar2;
