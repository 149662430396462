import React, { Component, useState } from "react";
import ProductsModal from "../Products/ProductsModal";
import ContactForm from "./ContactForm";
import "./Contact.css";
import emailjs from '@emailjs/browser';
import {Alert, Button} from 'react-bootstrap';


class Contact extends Component {
  constructor() {
    super();

    this.state = {
      result: {
        status:{text:"unknown"},
        variant:"primary",
        variantOutline:"outline-primary",
        message:"No new message",
        showAlert: false
      },
      response_message: "",
      submitBtnVal: "Submit",
      resultClass: "",
      products_cart: { 
        canibus_topical: {name:"Canibus Topical", price:0, qty:0, checked:false},
        thc_tetrahydrocannabinol_drops: {name:"THC Tetrahydrocannabinol Drops", price:0, qty:0, checked:false},
        cbd_oil_drops: {name:"Cbd Oil Drops", price:0, qty:0, checked:false},
        cannahoney: {name:"Cannahoney", price:0, qty:0, checked:false}
      },
      orderTotal: 0,
      ordered_items: "",
    };

    this.validateForm = this.validateForm.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  calcTotal = (e) => {
    let field_value = JSON.parse(e.target.value);
    let product_price = field_value[1];
    let product_quantity = 1;

    // Set values in cart based on product checklist
    var products_cart = this.state.products_cart;
    if (e.target.checked) {
      products_cart[e.target.id].price = product_price;
      products_cart[e.target.id].qty = product_quantity;
    }
    else {
      products_cart[e.target.id].price = 0;
      products_cart[e.target.id].qty = 0;
    }
    products_cart[e.target.id].checked = e.target.checked;

    // Calculate order total
    var total = 0;
    var items = "";
    Object.entries(products_cart).forEach(myFunction);
    function myFunction(item, index)
    {
      if (item[1].price > 0) {
        total += item[1].price;
        items += item[1].name+"(R"+item[1].price+") \n ";
      }
    }

    this.setState({ products_cart, orderTotal:total, ordered_items:items });
    //console.log("ordered_items:",this.state.ordered_items);
    //console.log("orderTotal:",this.state.orderTotal);
  };

  sendEmail(e) {
    e.preventDefault();

    this.setState({result: {
      status:{text:""},
      variant:"primary",
      variantOutline:"outline-primary",
      message:"Please wait while we submit your order...",
      showAlert: true
    }});

    var validForm = this.validateForm(e);

    console.log("validForm:",validForm);

    if(validForm){
      emailjs.sendForm('service_ilrq3ql', 'template_1lfc7nb', e.target, '4w7twL4CoFaXhrhgy')
      .then((result) => {
          console.log(result);
          
          this.setState({result: {
            status:{text:result.text},
            variant:"success",
            variantOutline:"outline-success",
            message:"Your order has been submitted",
            showAlert: true
          }});

      }, (error) => {
          console.log(error);

          this.setState({result: {
            status:{text:error.text},
            variant:"danger",
            variantOutline:"outline-danger",
            message:"There was an error sending your order, please try again",
            showAlert: true
          }});
      });
    }
  }

  validateForm(e) {

    var form = new FormData(e.target);
    var person = {};
    form.forEach((value, key) => (person[key] = value));
    var msg = "Please enter";
    var valAlert = false;

    if(person.first_name == "" || person.first_name == null){
      valAlert = true;
      msg += " your first name";
    }

    // validate: contact_number
    var phoneno = /^\d{10}$/;
    if(person.contact_number == null || person.contact_number.match(phoneno) == null) {
      valAlert = true;
      msg += ", a valid 10 digit contact number, ";
    }

    // validate: email_address
    var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(person.email_address.match(emailRegex) == null) {
      valAlert = true;
      msg += ", a valid email address";
    }

    if(valAlert){
      this.setState({result: {
        status:{text:""},
        variant:"danger",
        variantOutline:"outline-danger",
        message:msg,
        showAlert: true
      }});

      return false;
    }
    else if(this.state.orderTotal == 0){
      // validate: orderTotal
      this.setState({result: {
        status:{text:""},
        variant:"warning",
        variantOutline:"outline-warning",
        message:"Please note that you have not selected any products",
        showAlert: true
      }});
      return false;
    }
    else {
      return true;
    }
  }

  render() {
    function AlertDismissible(result) {
      var r = result.result;
      var [show, setShow] = useState(r.showAlert);
      
      // console.log(r);
      return (
        <>
          <Alert show={show} variant={r.variant}>
            <p>{r.message}</p>
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant={r.variantOutline}>
                Close
              </Button>
            </div>
          </Alert>
    
          {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}
        </>
      );
    }

    return (
      <div id="contact">
        <div className="container mb-5">
          <div className="row">
            <div className="col-12 text-left">
              <h2 className="font-semi-bold mb-4">Let's Talk</h2>
              <h3 className="tel-no font-semi-bold">
                Contact us for more info or to place an order
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-12">
              <div className="row mt-3">
                <div className="col-3">
                  <strong>Bonolo:</strong>
                </div>
                <div className="col-6">
                  <a href="tel:+27 60 577 6422">(+27) 60 577 6422</a>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <strong>Athi:</strong>
                </div>
                <div className="col-6">
                  <a href="tel:+27 73 836 9313">(+27) 73 836 9313</a>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <strong>Email:</strong>
                </div>
                <div className="col-6">hello@groundedhealing.co.za</div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  
                </div>
                <div className="col-6">
                  <p>
                    <a href="https://www.instagram.com/groundedhealing2/" target="_blank" rel="noopener">
                      <img src={require("../../assets/images/social/instagram_icon.png")} width="50"/></a>&nbsp; &nbsp;
                    <a href="https://api.whatsapp.com/send?phone=27605776422" target="_blank" rel="noopener">
                      <img src={require("../../assets/images/social/whatsapp_icon.png")} width="50"/>
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="contact-form col-md-6 col-sm-12">
              <AlertDismissible result={this.state.result} />
              <form autoComplete="off" onSubmit={this.sendEmail}>
                <div className="input-contain">
                  <ProductsModal orderTotal={this.state.orderTotal} onProductChange={this.calcTotal} />
                </div>

                <input type="hidden" id="ordered_items" name="ordered_items" value={this.state.ordered_items} />
                <input type="hidden" id="orderTotal" name="order_total" value={this.state.orderTotal} />

                <ContactForm />

                <div className="submit-btn">
                  <input
                    id="contact-submit"
                    className="top-content-30 gh_btn_secondary"
                    type="submit"
                    value={this.state.submitBtnVal}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
