import React, { Component } from "react";
import "./LearnMore.css";

class LearnMore extends Component {
  render() {
    return (
      <div id="learn-more">
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <h4>What is Cannabis?</h4>
              <p>
                Cannabis is a herb that comes from the cannabis plant also well
                known as marijuana. People use the dried leaves, seed oil and
                other parts of a cannabis plant, some ingredients in cannabis
                are psychoactive but others are not, the potency and balance of
                the ingredients vary. Cannabis has been used for centuries as an
                alternative to heal and treat many illnesses as well as for
                recreational purposes.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-left">
              <h4>Why Cannabis:</h4>
              <ul>
                <li>Insomnia</li>
                <li>Pain</li>
                <li>Arthritis</li>
                <li>Anxiety</li>
                <li>Depression</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-left"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default LearnMore;
