import React, { Component } from "react";
import Navbar2 from "../Navbar/Navbar2";
import Footer from "../Footer/";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Testimonials.css";

class AllTestimonials extends Component {
  render() {
    const slideImages1 = [
      {
        url: require("../../assets/images/testimonials/testimonial_boiling_water_01.png"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_boiling_water_02.png"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_boiling_water_03.png"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_boiling_water_04.png"),
        title: "Boiling water accident",
      },
    ];

    const slideImages2 = [
      {
        url: require("../../assets/images/testimonials/testimonial_radiation_from_breast_cancer_01.png"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_radiation_from_breast_cancer_02.png"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_radiation_from_breast_cancer_03.png"),
        title: "Boiling water accident",
      },
    ];

    const slideImages3 = [
      {
        url: require("../../assets/images/testimonials/testimonial_cancer_02.jpg"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_cancer_02.jpg"),
        title: "Boiling water accident",
      },
      {
        url: require("../../assets/images/testimonials/testimonial_cancer_03.jpg"),
        title: "Boiling water accident",
      },
    ];

    return (
      <div id="all-testimonials">
        <Navbar2 />

        <div className="container">
          <div className="row center">
            <div className="col-12">
              <h2>Our Testimonials</h2>
              Disclaimer: Images are not for sensitive viewers.
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12 center">
              <Slide>
                {slideImages1.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img
                      src={slideImage.url}
                      title={slideImage.title}
                      alt={slideImage.title}
                      className="testimonial_image"
                    />
                  </div>
                ))}
              </Slide>
            </div>
            <div className="col-md-7 col-sm-12 testimonial-text">
              <p>
                <strong>Boiling water accident:</strong> <br />
                I used the Topical and the Cannahoney for repair and
                supplemented the healing process with the CBD drops. <br />
                Duration: 4 weeks
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12 center">
              <Slide>
                {slideImages2.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img
                      src={slideImage.url}
                      title={slideImage.title}
                      alt={slideImage.title}
                      className="testimonial_image"
                    />
                  </div>
                ))}
              </Slide>
            </div>
            <div className="col-md-7 col-sm-12 testimonial-text">
              <p>
                <strong>Radiation From Breast Cancer:</strong> <br />
                I used the Topical and the CBD drops
                <br />
                Duration: 1 week.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12 center">
              <Slide>
                {slideImages3.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img
                      src={slideImage.url}
                      title={slideImage.title}
                      alt={slideImage.title}
                      className="testimonial_image"
                    />
                  </div>
                ))}
              </Slide>
            </div>
            <div className="col-md-7 col-sm-12 testimonial-text">
              <p>
                <strong>Cancer:</strong> <br />
                I used the Topical and Cannahoney while also using the CBD drops
                <br /> Duration: closing of the open wound 4 weeks and 6 weeks
                of complete healing.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 col-sm-12 center mb-5">
              <a href="/#testimonials" className="gh_btn_secondary">
                Close
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AllTestimonials;
