import React, { Component } from "react";
import $ from "jquery";
// import { Link } from "react-router-dom";
import "./Navbar.css";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionId: this.props.sectionId,
    };

    console.log("sectionID:" + this.state.sectionId);

    this.smoothScroll = this.smoothScroll.bind(this);
  }

  smoothScroll(e) {
    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();

    // target element id
    let id = $(e.target).attr("href");

    //Pushes ID to address bar url
    window.history.pushState("", "", id);

    $("html, body").animate(
      {
        scrollTop: $(id).offset().top,
      },
      700,
      function () {}
    );
  }

  render() {
    return (
      <nav className="navbar fixed-top navbar-expand-sm navbar-light navbar-custom">
        <div className="container">
          <a className="navbar-brand" href="#home">
            <img
              src={require("../../assets/images/Grounded_Healing_Logo.webp")}
              title="Grounded Healing"
              alt="Grounded Healing"
              width={90}
            />
          </a>
          <div className="navbar-heading">
            <span className="color-primary">GROUNDED</span> <br />
            <span className="color-secondary">HEALING</span>
          </div>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a
                  className="nav-link"
                  href="#home"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={this.smoothScroll}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#products"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={this.smoothScroll}
                >
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#testimonials"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={this.smoothScroll}
                >
                  Testimonials
                </a>
                {/* <Link to="/testimonials">Testimonials</Link> */}
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={this.smoothScroll}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
