import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  render() {
    const dt = new Date();
    const currentYear = dt.getFullYear();
    return (
      <div id="footer">
        <div className="footer-container">
          <p>
            &copy;{currentYear} <span>Grounded Healing</span> - Developed by{" "}
            <a
              href="mailto:construct.m4@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Construct M
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
