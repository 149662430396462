import React, { Component } from "react";
import "./Modal.css";

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div>
      <div className={showHideClassName}>
        <div className="modal-main">
          {children}
          <button
            className="gh_btn_secondary_2"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
