import React from "react";
import TextFieldd from '@mui/material/TextField';
import "@material/react-text-field/dist/text-field.css";
import "./ContactForm.css";

class ContactForm extends React.Component {
  render() {
    const fieldStyle = {width:'100%', border:"green"};
    return (
      <div>
        <div className="input-contain">
        <TextFieldd
          id=""
          label="First Name:"
          type="input"
          name="first_name"
          variant="standard"
          style={fieldStyle}
        />
        </div>

        <div className="input-contain">
        <TextFieldd
          id=""
          label="Last Name:"
          type="input"
          name="last_name"
          variant="standard"
          style={fieldStyle}
        />
        </div>

        <div className="input-contain">
        <TextFieldd
          id=""
          label="Contact Number:"
          type="number"
          name="contact_number"
          variant="standard"
          style={fieldStyle}
        />
        </div>

        <div className="input-contain">
        <TextFieldd
          id=""
          label="Full Address:"
          type="input"
          name="address"
          variant="standard"
          style={fieldStyle}
        />
        </div>

        <div className="input-contain">
        <TextFieldd
          id=""
          label="Email Address:"
          type="email"
          name="email_address"
          variant="standard"
          style={fieldStyle}
        />
        </div>
      </div>
    );
  }
}

export default ContactForm;
