import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./components/Main";
import Products from "./components/Products/";
import AllTestimonials from "./components/Testimonials/AllTestimonials";
import "./App.css";
import SimpleSelect from "./App.test.js";

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <Main /> */}
        {/* <SimpleSelect /> */}
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/products" component={Products} />
            <Route path="/alltestimonials" component={AllTestimonials} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
