import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Testimonials.css";
import "./AllTestimonials";

class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="row center">
            <div className="col-12">
              <h2>Our Testimonials</h2>
              Disclaimer: Images are not for sensitive viewers.
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12 center">
              <img
                src={require("../../assets/images/testimonials/testimonial_boiling_water_01.png")}
                title="Boiling water accident"
                alt="Boiling water accident"
                className="testimonial_image"
              />
            </div>
            <div className="col-md-7 col-sm-12 testimonial-text">
              <p>
                <strong>Boiling water accident:</strong> <br />
                I used the Topical and the Cannahoney for repair and
                supplemented the healing process with the CBD drops. <br />
                Duration: 4 weeks
              </p>

              <p className="mt-5">
                <Link to="/alltestimonials" className="gh_btn_secondary">
                  Read more
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default testimonials;
