import React, { Component } from "react";
import $ from "jquery";
import Navbar from "./Navbar/";
import Home from "./Home/";
import LearnMore from "./LearnMore/";
import Products from "./Products/";
import Testimonials from "./Testimonials/";
import Contact from "./Contact/";
import Footer from "./Footer/";

import "./Main.css";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionId: "",
    };
  }

  componentDidMount() {
    // Cache selectors
    var topMenu = $(".navbar-nav"),
      topMenuHeight = topMenu.outerHeight() + 15,
      // All list items
      menuItems = topMenu.find("a"),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function () {
        var item = $($(this).attr("href"));
        if (item.length) {
          return item;
        }
        return null;
      });

    // Bind to scroll
    $(window).scroll(function () {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;

      // Get id of current scroll item
      var cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop) return this;
        return null;
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : "";

      // Set/remove active class
      menuItems
        .parent()
        .removeClass("active")
        .end()
        .filter("[href='#" + id + "']")
        .parent()
        .addClass("active");
    });
  }

  render() {
    return (
      <div id="main-wrapper">
        <Navbar sectionId={this.state.sectionId} />
        <Home />
        <LearnMore />
        <Products />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default Main;
