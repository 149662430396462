import React, { Component, useState } from "react";
import Modal from "../Modal/";

class ProductsModal extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    // this..calcTotal = this.props.this.props.onProductChange.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <main>
        <button
          className="gh_btn_secondary_2"
          type="button"
          onClick={this.showModal}
        >
          Select Products
        </button>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          {/* <p>Selected Products</p> */}
          <div className="container">
            <div className="row">
              <div className="col-md-1 col-sm-2 product-checkbox">
                <input
                  type="checkbox"
                  id="canibus_topical"
                  name="Canibus topical (50ml)"
                  value='["Cannabis topical",500]'
                  onChange={this.props.onProductChange}
                />
              </div>
              <div
                for="canibus_topical"
                className="col-md-4 col-sm-3 product-name"
              >
                Cannabis topical  (50ml)
              </div>
              <div className="col-md-4 col-sm-3 product-price">R500</div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-2 product-checkbox">
                <input
                  type="checkbox"
                  id="thc_tetrahydrocannabinol_drops"
                  name="THC Tetrahydrocannabinol drops (10ml)"
                  value='["THC Tetrahydrocannabinol drops",450]'
                  onChange={this.props.onProductChange}
                />
              </div>
              <div
                for="thc_tetrahydrocannabinol_drops"
                className="col-md-4 col-sm-3 product-name"
              >
                THC Tetrahydrocannabinol drops  (10ml)
              </div>
              <div className="col-md-4 col-sm-3 product-price">R450</div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-2 product-checkbox">
                <input
                  type="checkbox"
                  id="cbd_oil_drops"
                  name="CBD Oil drops (10ml)"
                  value='["CBD Oil drops",450]'
                  onChange={this.props.onProductChange}
                />
              </div>
              <div
                for="cbd_oil_drops"
                className="col-md-4 col-sm-3 product-name"
              >
                CBD Oil drops  (10ml)
              </div>
              <div className="col-md-4 col-sm-3 product-price">R450</div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-2 product-checkbox">
                <input
                  type="checkbox"
                  id="cannahoney"
                  name="Cannahoney (10ml)"
                  value='["Cannahoney",550]'
                  onChange={this.props.onProductChange}
                />
              </div>
              <div for="cannahoney" className="col-md-4 col-sm-3 product-name">
                Cannahoney  (50ml)
              </div>
              <div className="col-md-4 col-sm-3 product-price">R550</div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-2 product-checkbox">
              </div>
              <div className="col-md-4 col-sm-3 product-name">
                <strong>TOTAL</strong>
              </div>
              <div className="col-md-4 col-sm-3 product-price">{this.props.orderTotal}</div>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

export default ProductsModal;
